.navbar {
    padding: 0;
}
.navbar-nav a {
    font-size: 18px;
    font-weight: 400;
    color: blueviolet;
    text-decoration: none;
    padding: 8px 12px;
}
.navbar-nav a:hover {
    /* color: blue; */
    background-color: #eee;
}
.user-icon {
    width: 36px;
    height: 36px;
    border: 1px solid gray;
    padding: 1px;
    border-radius: 50%;
}