.dash-item {
    cursor: pointer;
}
.dash-item:hover {
    color: #333;
    background-color: #eef;
}
.dash-nav a {
    font-size: 18px;
    text-decoration: none;
    padding: 8px 12px;
}
.dash-nav a:hover {
    background-color: #eef;
}