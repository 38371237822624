.activity-item {
    border: 2px solid gray;
    transition: 0.3s;
}
.activity-item:hover {
    border-color: blueviolet;
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.15);
}
.activity-item:hover p {
    transition: 0.3s;
    color: blueviolet;
}